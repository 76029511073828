<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('MEDICOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="40"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <buscaCepEndereco :cep.sync="model.cep"
                                              :endereco.sync="model.endereco"
                                              :bairro.sync="model.bairro"
                                              :cidadeid.sync="model.cidadeId"
                                              :cidadenome.sync="model.cidadeNome"></buscaCepEndereco>


                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('MEDICOCADASTRO.FONE')">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('MEDICOCADASTRO.CEL')">
                                        <b-input v-model="model.celular" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-half-mobile">
                                    <b-field :label="$t('MEDICOCADASTRO.DATA')">
                                        <b-datepicker  editable v-model="model.dataDeNascimento" trap-focus></b-datepicker>
                                    </b-field>
                                </div>
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('MEDICOCADASTRO.IDADE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="idade" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-multiline is-mobile">
                                <!-- {{model}} -->
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('MEDICOCADASTRO.CPF')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cpf" v-mask="'###.###.###-##'">
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('MEDICOCADASTRO.CONSELHO')}}</label>
                                        <div class="select full-width">
                                            <select v-model="model.conselho" class="full-width">
                                                <option v-for="conselho in $store.state.conselhos"
                                                        :key="conselho.value"
                                                        :value="conselho.value">
                                                    {{ conselho.text }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('MEDICOCADASTRO.UF')}}</label>
                                        <div class="select full-width">
                                            <select v-model="model.conselhoUF" class="full-width">
                                                <option v-for="uf in $store.state.estados"
                                                        :key="uf"
                                                        :value="uf">
                                                    {{ uf}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('MEDICOCADASTRO.NUMEROCONSELHO')}}</label>
                                        <div class="control">
                                            <b-input type="text" v-model="model.conselhoNumero" maxlength="15" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="column is-half-mobile">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.CODOPERADORA')}}</label>
                                            <div class="control">
                                                <b-input type="text" v-model="model.codigoOperadora" maxlength="14" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="column is-half-mobile">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.CNES')}}</label>
                                            <div class="control">
                                                <b-input type="text" v-model="model.cnes" maxlength="7" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="column is-half-mobile">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.CBOS')}}</label>
                                            <cbos :items.sync="model.medicoCbos" campoChave="id" campoValor="codigo" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <searchIdName :id.sync="model.convenioPadraoId" :nome.sync="model.convenioPadraoNome" :label="$t('MEDICOCADASTRO.CONVPADRAO')" table="Convenio"></searchIdName>

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('MEDICOCADASTRO.EMAIL')"
                                                 ref="email"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input maxlength="50" type="email" v-model="model.email" @blur="verificarEmail" :readonly="model.possuiUsuario"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('MEDICOCADASTRO.OBSERVACAO')}}</label>
                                        <div class="control">
                                            <textarea class="textarea" maxlength="500" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('MEDICOCADASTRO.FOTO')}}
                                </div>
                                <div v-if="!capturando">
                                    <div class="panel-block">
                                        <figure class="image" v-if="model.foto">
                                            <img :src="model.foto" ref="foto">
                                        </figure>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="panel-block">
                                        <vue-web-cam ref="webcam"
                                                     :device-id="deviceId"
                                                     width="100%"
                                                     @cameras="onCameras" />
                                    </div>
                                    <div class="panel-block">
                                        <div class="field">
                                            <div class="select">
                                                <select v-model="camera">
                                                    <option>-- {{$t('MEDICOCADASTRO.SELECT')}} --</option>
                                                    <option v-for="device in devices"
                                                            :key="device.deviceId"
                                                            :value="device.deviceId">
                                                        {{ device.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <b-button :class="capturando ? 'is-success' : 'is-link '"
                                              icon-left="camera"
                                              @click="onCapture" :title="$t('MEDICOCADASTRO.CAPTURAR')"
                                              expanded>
                                    </b-button>
                                </div>
                                <div class="panel-block">
                                    <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                            <input class="file-input" type="file" name="foto" ref="fileSele" @change="imageFileSelected">
                                            <span class="file-cta">
                                                <span class="file-icon">
                                                    <i class="fas fa-upload"></i>
                                                </span>
                                                <span class="file-label">
                                                    {{$t('MEDICOCADASTRO.ESCOLHER')}}
                                                </span>
                                            </span>
                                            <span class="file-name">{{$t('MEDICOCADASTRO.FOTO')}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="isInRole('usuarioweb-criar')" class="panel-block">
                                    <div class="field full-width">
                                        <b-switch v-model="model.criarUsuario" v-if="!model.possuiUsuario">
                                            {{$t('MEDICOCADASTRO.CRIARUSUARIO')}}
                                        </b-switch>
                                        <b-button v-if="model.possuiUsuario"
                                                  class="button is-danger "
                                                  @click="deletarUsuarioMedico(model.id)"
                                                  expanded>
                                            {{$t('MEDICOCADASTRO.CANCELAUSUARIO')}}
                                        </b-button>

                                    </div>
                                </div>
                            </article>
                            <article v-if="model.id != null" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('MEDICOCADASTRO.GUIAS')}}
                                </div>
                                <div class="panel-block is-justified-between tile is-12 btn-guias">                                    
                                    <router-link :to="{ name: 'guia-list', query: { medicoId: model.id }}" class="button is-link tile is-4 ">
                                        {{$t('MEDICOCADASTRO.LISTAR')}}
                                    </router-link>
                                    <a :href="'/Rel/PlanilhaDeResultados?medicoId=' + model.id" class="button is-info">Resultados</a>
                                </div>
                            </article>

                            <historico-alteracoes :tabela="'Medico'" :chave="model.id"></historico-alteracoes>

                            <article class="panel" v-if="config.moduloSline">
                                <div class="panel-heading has-text-centered">
                                    www.resultados.com.br
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.publicaResultadosNaInternet">
                                            {{$t('MEDICOCADASTRO.PUBLICAR')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <p v-show="publicarresultado" class="control has-icons-left">
                                    <input class="input" type="text" placeholder="Chave" v-model="model.senha">
                                    <span class="icon is-left">
                                        <i class="fas fa-key" aria-hidden="true"></i>
                                    </span>
                                </p>
                            </article>
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('MEDICOCADASTRO.CODIGOSINTEGRACAO')}}
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>{{$t('MEDICOCADASTRO.TIPO')}}</th>
                                                <th>{{$t('MEDICOCADASTRO.CODIGO')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select :placeholder="$t('MEDICOCADASTRO.INTEGRADOR')" v-model="item.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input" type="text" v-model="item.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                       {{$t('MEDICOCADASTRO.ADDCODIGO')}}
                                    </button>
                                </div>
                            </article>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('medico-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('MEDICOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('MEDICOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style lang="scss">
    .full-width {
        width: 100%;
    }

    .image {
        width: 100%;
    }
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCepEndereco from '@/components/buscaCepEndereco.vue'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import cbos from '@/components/manutencao/cbos.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    bairro: null,
                    cbosPadrao: null,
                    celular: null,
                    cep: null,
                    cnes: null,
                    codigoIntegracao: null,
                    codigoOperadora: null,
                    codigoIntegrador: [],
                    conselho: null,
                    conselhoInfo: null,
                    conselhoNumero: null,
                    conselhoUF: null,
                    convenioPadrao: null,
                    conselhos: [],
                    cpf: null,
                    dataDeNascimento: null,
                    dataUltimaModificacao: null,
                    email: null,
                    endereco: null,
                    fone: null,
                    nome: null,
                    nomeFonetico: null,
                    obervacoes: null,
                    rg: null,
                    estados: [],
                    cidades: [],
                    possuiUsuario: null,
                    criarUsuario: false,
                    clientId: null,
                    foto: null,
                    convenioPadraoId: null,
                    convenioPadraoNome: null,
                    cidadeId: null,
                    cidadeNome: null,
                    desativado: false,
                    medicoCbos: null,
                },

                capturando: false,
                camera: null,
                deviceId: null,
                devices: [],
                carregandoHistoricoArquivos: true,
                historicoArquivos: [],
                salvandoDados: false,
                nomeSocial: false,
                criarGuia: false,
                fotoAlterada: false,
                usuarioEstadoInicial: false,
                retornarMedico: Boolean(this.$route.query.retornarMedico) ?? false,
                opcoesIndicacao: [
                    'Google',
                    'Facebook',
                    'Instagram',
                    'Panfleto',
                    'Revista',
                    'Solicitante',
                    'Amigo',
                    'Outros'
                ]
            };
        },
        components: {
            buscaCepEndereco,
            titlebar,
            searchIdName,
            historicoAlteracoes,
            cbos
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/medico?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        if(this.model.dataDeNascimento){
                            this.model.dataDeNascimento = new Date(this.model.dataDeNascimento);
                        }
                        this.model.medicoCbos = this.model.medicoCbos?.filter(i => i.codigo?.trim()?.length);
                        this.model.criarUsuario = false;
                        this.possuiUsuario = this.model.possuiUsuario;
                        this.getImage();
                    })
            }

            document.addEventListener('visibilitychange', this.verificaRetornarMedico, false);   
        },
        computed: {
            titleStack() {
                return [
                    this.$t('MEDICOCADASTRO.MANUTENCAO'),
                    this.$t('SISTEMA.SOLICITANTE'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            idade() {
                if(this.model.dataDeNascimento == null){
                    return "";
                }
                
                return ~~((Date.now() - this.model.dataDeNascimento) / (31557600000));
            },
            device() {
                return this.devices.find(n => n.deviceId === this.deviceId);
            },
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapState('componentes', [
                'novoMedico'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
            camera: function (id) {
                this.deviceId = id;
            },
            devices: function () {
                // Once we have a list select the first one
                const [first] = this.devices;
                if (first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            }
        },
        methods: {
            verificaRetornarMedico() {         
                console.log("verificaRetornarMedico");
                if(localStorage.retornarMedico > 0 && !this.retornarMedico){                    
                    this.model.id = localStorage.retornarMedico;
                    localStorage.retornarMedico = null;
                }                
            },              
            imageFileSelected(e) {
                const { name, type, size } = e.target.files[0];
                let imageData = null;
                if (FileReader && size) {
                    const fr = new FileReader();
                    fr.onload = () => {
                        imageData = fr.result;
                        this.model.foto = imageData;
                        this.fotoAlterada = true;
                        this.$refs.foto.src = imageData;
                    }
                    fr.readAsDataURL(e.target.files[0]);
                }
            },
            async onSubmit() {
                if(this.config.convenioPadraoObrigatorioMedico){
                    if(this.model.convenioPadraoId == 0 || this.model.convenioPadraoId == null || this.model.convenioPadraoId == '' || this.model.convenioPadraoId == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MEDICOCADASTRO.CONVOBR'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }
                }
                
                this.salvandoDados = true;
                if (!this.nomeSocial && this.model.nomeSocial != null) {
                    this.model.nomeSocial = null
                }

                const newItem = Object.assign({}, this.model);
                newItem.medicoCboss = newItem.medicoCbos;

                if (newItem.cidadeId) {
                    newItem.Cidade = this.$store.state.cidades.find(c => c.id === newItem.cidadeId);
                } else {
                    newItem.Cidade = null;
                }

                if (newItem.convenioPadraoId) {
                    newItem.ConvenioPadrao = { id: newItem.convenioPadraoId, nome: newItem.convenioPadraoNome };
                }

                if (newItem.codigosIntegrador) {
                    newItem.codigosIntegrador = newItem.codigosIntegrador.map(i => {
                        return {
                            codigo: i.codigo,
                            integradorMedicoId: {
                                codigo: i.codigo,
                                integradorId: i.integradorId
                            }
                        }
                    });
                }

                if (this.model.foto && this.fotoAlterada) {
                    const dadosFotoArray = newItem.foto.split(',');
                    dadosFotoArray.shift();
                    newItem.foto = dadosFotoArray.join();
                } else {
                    newItem.foto = null;
                }

                if (!newItem.id) {
                    newItem.id = 0;
                }

                if (newItem.criarUsuario && !newItem.email?.length) {
                    this.salvandoDados = false;
                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('MEDICOCADASTRO.PREENCHEREMAIL'),
                        type: 'is-danger',
                        queue: false
                    });
                }

                //Removendo qualquer integrador que não esteja preenchido
                newItem.codigosIntegrador = newItem.codigosIntegrador?.filter(c => c.integradorMedicoId?.integradorId != null && c.integradorMedicoId?.codigo != null);

                this.$http.post(`/api/manutencao/medico?criarUsuario=${this.model.criarUsuario}`, newItem)
                    .then(res => {
                        if(this.retornarMedico) {
                            localStorage.retornarMedico = res.data.id;
                            window.close()
                        }

                        if (this.criarGuia) {
                            window.location = '/Guia/Create?medicoId=' + this.model.id
                        } else {
                            if (this.$route.params.return) {
                                this.$store.commit('componentes/SET_NOVO_MEDICO',
                                    {
                                        id: res.data.id,
                                        nome: res.data.nome,
                                        cboss: res.data.cboss
                                    })
                            }
                            this.$router.back()
                        }
                        this.salvandoDados = false;
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.salvandoDados = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MEDICOCADASTRO.ERROSALVARMEDICO') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            },

            onCapture() {
                if (this.capturando) {
                    this.model.foto = this.$refs.webcam.capture();
                    setTimeout(() => {
                        this.$refs.webcam.stop();
                        this.capturando = false;
                        this.fotoAlterada = true;
                    }, 500);
                } else {
                    this.capturando = true;
                    setTimeout(() => { this.$refs.webcam.start() }, 500);
                }
            },
            onCameras(cameras) {
                this.devices = cameras;
                // console.log("On Cameras Event", cameras);
            },
            onCameraChange(deviceId) {
                this.deviceId = deviceId;
                this.camera = deviceId;
                // console.log("On Camera Change Event", deviceId);
            },
            getImage() {
                if (!this.model.foto) {
                    this.model.foto = '/api/manutencao/medicoFoto?id=' + this.$route.params.id + `&_cl=${new Date().getTime()}`;
                }

                return this.model.foto;
            },
            verificarEmail() {
                this.$http.get(`/api/manutencao/VerificarUsuarioEmail?email=${this.model.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'médico' && res.data.id == this.model.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('MEDICOCADASTRO.OEMAIL') + res.data.email + this.$t('MEDICOCADASTRO.EMUSO') + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.model.email = null
                        }
                    });
            },
            deletarUsuarioMedico(id) {
                    this.$http.get(`/api/manutencao/DeletarUsuarioMedico?medicoId=${id}`).then(res => {
                       
                      if(res.data.ok){

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.messagem,
                                type: 'is-success',
                                queue: false
                            })                           
                            this.$router.back();
                       }else{
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.messagem,
                                type: 'is-danger',
                                queue: false
                            })
                       }
                        
                                                                                               
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MEDICOCADASTRO.ERROSALVARMEDICO') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })

            }
        }
    }
</script>